import { ShopOutlined } from '@ant-design/icons';
import { lazyLoad } from '../util';
import { type MenuRoute } from '../types';

const StoreStatistics = lazyLoad(
  async () => await import('@/pages/statistics')
);
const StoreList = lazyLoad(async () => await import('@/pages/store/list'));
const StoreEdit = lazyLoad(async () => await import('@/pages/store/edit'));
const StoreAdd = lazyLoad(async () => await import('@/pages/store/edit'));
const StoreTypeList = lazyLoad(
  async () => await import('@/pages/storeType/list')
);
const StoreRankList = lazyLoad(
  async () => await import('@/pages/storeRank/list')
);
const UploadStoreList = lazyLoad(
  async () => await import('@/pages/uploadStore/list')
);
const UploadStoreEdit = lazyLoad(
  async () => await import('@/pages/uploadStore/edit')
);

export const storeRoutes: MenuRoute[] = [
  {
    path: '/store',
    name: 'menu:storeManagement',
    key: 'store',
    identifier: '/store',
    type: 'subMenu',
    icon: <ShopOutlined />,
    routes: [
      {
        path: '/store/statistics',
        name: 'menu:storeStatistics',
        exact: true,
        key: 'store:statistics',
        component: StoreStatistics
      },
      {
        path: '/store/list',
        name: 'menu:storeList',
        exact: true,
        key: 'store:list',
        component: StoreList
      },
      {
        path: '/store/edit',
        name: 'menu:editStore',
        exact: true,
        key: 'store:list:edit',
        component: StoreEdit,
        hideInMenu: true,
        selectedKey: 'store:list'
      },
      {
        path: '/store/add',
        name: 'menu:addStore',
        exact: true,
        key: 'store:list:add',
        component: StoreAdd,
        hideInMenu: true,
        selectedKey: 'store:list'
      },
      {
        path: '/store/upload/list',
        name: 'menu:storeUploadRecords',
        exact: true,
        key: 'store:upload:list',
        component: UploadStoreList
      },
      {
        path: '/store/upload/edit',
        name: 'menu:storeUploadAudit',
        exact: true,
        key: 'store:upload:edit',
        component: UploadStoreEdit,
        hideInMenu: true,
        selectedKey: 'store:upload:list'
      },
      {
        path: '/store/upload/detail',
        name: 'menu:storeUploadDetail',
        exact: true,
        key: 'store:upload:detail',
        component: UploadStoreEdit,
        hideInMenu: true,
        selectedKey: 'store:upload:list'
      },
      {
        path: '/store/type/list',
        name: 'menu:storeTypeList',
        exact: true,
        key: 'store:type:list',
        component: StoreTypeList
      },
      {
        path: '/store/rank/list',
        name: 'menu:storeRankList',
        exact: true,
        key: 'store:rank:list',
        component: StoreRankList
      }
    ]
  }
];
