import { $http } from '@/service/http';
import qs from 'qs';
import { type CommonAxiosResponse } from '@/service/types';

const _str = (obj?: CommonObjectType): string =>
  qs.stringify(obj ?? {}, { arrayFormat: 'repeat' });

// 获取返佣产品列表
export const getRebateProductSkuListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/find/list',
    data: _str(data)
  }).post();
};

// 获取返佣产品详情
export const getRebateProductSkuDetailDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/find/id',
    data: _str(data)
  }).post();
};

// 新增返佣产品
export const addRebateProductSkuDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/add',
    data: _str(data)
  }).post();
};

// 更新返佣产品
export const updateRebateProductSkuDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/update',
    data: _str(data)
  }).post();
};

// 删除返佣产品
export const deleteRebateProductSkuDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/delete',
    data: _str(data)
  }).post();
};

// 查询返佣产品返佣金额
export const getRebateAmountDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/price/find/list',
    data: _str(data)
  }).post();
};

// 添加产品返佣金额
export const addRebateAmountDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/price/add',
    data: _str(data)
  }).post();
};

// 更新产品返佣金额
export const updateRebateAmountDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/price/update',
    data: _str(data)
  }).post();
};

// 删除产品返佣金额
export const deleteRebateAmountDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/product/sku/price/delete',
    data: _str(data)
  }).post();
};

// 获取货币单位
export const getCurrencyDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/currency/find/list',
    data: _str(data)
  }).post();
};

// 查询返佣国家列表
export const getRebateCountryListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/country/find/list',
    data: _str(data)
  }).post();
};

// 添加返佣国家
export const addRebateCountryDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/country/add',
    data: _str(data)
  }).post();
};

// 更新返佣国家
export const updateRebateCountryDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/country/update',
    data: _str(data)
  }).post();
};

// 删除返佣国家
export const deleteRebateCountryDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/country/delete',
    data: _str(data)
  }).post();
};

// 查询返佣记录列表
export const getRebateRecordListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/record/find/list',
    data: _str(data)
  }).post();
};

// 更新返佣记录
export const updateRebateRecordDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/record/update',
    data: _str(data)
  }).post();
};

// 删除返佣记录
export const deleteRebateRecordDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/record/delete',
    data: _str(data)
  }).post();
};

// 返佣记录详情
export const getRebateRecordDetailDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/record/find/id',
    data: _str(data)
  }).post();
};

// 新增返佣记录
export const addRebateRecordDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/record/add',
    data: _str(data)
  }).post();
};

// 余额列表查询
export const getRebateBalanceListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/balance/find/list',
    data: _str(data)
  }).post();
};

// 记账列表查询
export const getRebateAccountingListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/accounting/find/list',
    data: _str(data)
  }).post();
};

// 查询返佣操作列表
export const getRebateOperationListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/operation/find/list',
    data: _str(data)
  }).post();
};

// 查询返佣账号列表
export const getRebateAccountListDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/account/find/list',
    data: _str(data)
  }).post();
};

// 查询返佣账号详情
export const getRebateAccountDetailDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/account/find/id',
    data: _str(data)
  }).post();
};

// 更新返佣账号
export const updateRebateAccountDao = async (
  data?: CommonObjectType
): Promise<CommonAxiosResponse> => {
  return await $http({
    url: '/api/admin/rebate/account/update',
    data: _str(data)
  }).post();
};
