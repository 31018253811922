import { GroupOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const GroupList = lazyLoad(async () => await import('@/pages/group/list'));

export const groupRoutes: MenuRoute[] = [
  {
    path: '/group/list',
    name: 'menu:groupManagement',
    key: 'group',
    identifier: '/group',
    icon: <GroupOutlined />,
    component: GroupList
  }
];
