import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { type RebateCountry } from '@/pages/rebate/country/list';
import { getRebateCountryListDao } from '@/service/modules/rebate';
import { type RootState } from '..';

interface RebateState {
  countries: RebateCountry[];
}

const initialState: RebateState = {
  countries: []
};

const getRebateCountryList = async () => {
  return await getRebateCountryListDao({
    page_number: 1,
    page_size: -1
  });
};

// 异步 thunk，用于获取国家数据
export const fetchCountriesIfNeeded = createAsyncThunk(
  'rebate/fetchCountriesIfNeeded',
  async (_, { getState, dispatch }) => {
    const state = getState() as { rebate: RebateState };
    if (state.rebate.countries.length > 0) {
      return state.rebate.countries; // 已有数据时直接返回
    }

    const res = await getRebateCountryList(); // API 请求获取数据
    return res.data.array ?? [];
  }
);

const rebateSlice = createSlice({
  name: 'rebate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesIfNeeded.fulfilled, (state, action) => {
      // 确保异步获取成功的数据会更新到 Redux 状态中
      state.countries = action.payload;
    });
  }
});

export const selectCountries = (state: RootState): RebateState['countries'] =>
  state.rebate.countries;

export default rebateSlice.reducer;
