import { type MenuRoute } from '@/route/types';
import { storeRoutes } from './modules/store';
import { supplierRoutes } from './modules/supplier';
import { chainStoreRoutes } from './modules/chainStore';
import { promoterRoutes } from './modules/promoter';
import { checkInRoutes } from './modules/checkIn';
import { questionnaireRoutes } from './modules/questionnaire';
import { regionRoutes } from './modules/region';
import { rebateRoutes } from './modules/rebate';
import { lazyLoad } from './util';
import { groupRoutes } from './modules/group';
import { fileRoutes } from './modules/file';
import { qiniuRoutes } from './modules/qiniu';
import { permissionRoutes } from './modules/permission';
import { configRoutes } from './modules/config';
import { languageRoutes } from './modules/language';
import { appVersionRoutes } from './modules/appVersion';

const Home = lazyLoad(async () => await import('@/pages/home'));

const ErrorPage = lazyLoad(
  async () => await import('@/pages/public/errorPage')
);

/**
 * path 跳转的路径
 * component 对应路径显示的组件
 * exact 匹配规则，true的时候则精确匹配。
 * hideInMenu 是否在menu中展示
 * subMenu是否有子菜单
 * selectedKey 选中的菜单项 key
 * key 唯一标识当前路由配置的属性，用于标识和管理路由项、menu的key规则
 * noAuth 不需要权限
 */
const preDefineRoutes: MenuRoute[] = [
  {
    path: '/',
    name: 'menu:blankPage',
    exact: true,
    key: 'home',
    hideInMenu: true,
    component: Home
  },
  {
    path: '/403',
    name: 'menu:noPermission',
    exact: true,
    key: 'error',
    hideInMenu: true,
    component: ErrorPage
  },
  ...storeRoutes,
  ...supplierRoutes,
  ...chainStoreRoutes,
  ...promoterRoutes,
  ...checkInRoutes,
  ...questionnaireRoutes,
  ...regionRoutes,
  ...rebateRoutes,
  ...groupRoutes,
  ...languageRoutes,
  ...fileRoutes,
  ...qiniuRoutes,
  ...appVersionRoutes,
  ...permissionRoutes,
  ...configRoutes
];

export default preDefineRoutes;
