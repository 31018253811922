import { PartitionOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const ChainStoreList = lazyLoad(
  async () => await import('@/pages/chainStore/list')
);

export const chainStoreRoutes: MenuRoute[] = [
  {
    path: '/chain_store',
    name: 'menu:chainStoreManagement',
    key: 'chainStore',
    identifier: '/chain_store',
    type: 'subMenu',
    icon: <PartitionOutlined />,
    routes: [
      {
        path: '/chain_store/list',
        name: 'menu:chainStoreList',
        exact: true,
        key: 'chainStore:list',
        component: ChainStoreList
      }
    ]
  }
];
