import { SettingOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const ConfigList = lazyLoad(async () => await import('@/pages/config/list'));
const ConfigEdit = lazyLoad(async () => await import('@/pages/config/edit'));

export const configRoutes: MenuRoute[] = [
  {
    path: '/config/list',
    name: 'menu:configManagement',
    key: 'config',
    identifier: '/web/config',
    icon: <SettingOutlined />,
    component: ConfigList,
    routes: [
      {
        path: '/config/edit',
        name: 'menu:editConfig',
        exact: true,
        key: 'config:edit',
        hideInMenu: true,
        selectedKey: 'config',
        component: ConfigEdit
      },
      {
        path: '/config/add',
        name: 'menu:addConfig',
        exact: true,
        key: 'config:add',
        hideInMenu: true,
        selectedKey: 'config',
        component: ConfigEdit
      }
    ]
  }
];
