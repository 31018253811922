import { FolderOpenOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const File = lazyLoad(async () => await import('@/pages/file'));

export const fileRoutes: MenuRoute[] = [
  {
    path: '/file',
    name: 'menu:fileManagement',
    key: 'file',
    identifier: '/file',
    icon: <FolderOpenOutlined />,
    component: File
  }
];
