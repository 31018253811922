import '@/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { type FC, lazy, Suspense, useEffect } from 'react';

const Login = lazy(async () => await import('@/pages/login/index'));
const Container = lazy(async () => await import('@/pages/container/index'));

const App: FC = () => {
  // https://stackoverflow.com/questions/70368760/react-uncaught-referenceerror-process-is-not-defined/71083312#71083312
  // 解决process未定义问题
  useEffect(() => {
    window.process = {
      ...window.process
    };
  }, []);
  return (
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }}
    >
      <Suspense>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/*" key="container" element={<Container />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
