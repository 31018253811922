import { CloudOutlined } from '@ant-design/icons';
import { lazyLoad } from '../util';
import { type MenuRoute } from '../types';

const QiniuNodeList = lazyLoad(
  async () => await import('@/pages/qiniu/node/list')
);
const QiniuNodeGroupList = lazyLoad(
  async () => await import('@/pages/qiniu/node/group')
);

export const qiniuRoutes: MenuRoute[] = [
  {
    path: '/qiniu',
    name: 'menu:qiniuManagement',
    key: 'qiniu',
    identifier: '/qiniu',
    type: 'subMenu',
    icon: <CloudOutlined />,
    routes: [
      {
        path: '/qiniu/node/list',
        name: 'menu:nodeList',
        exact: true,
        key: 'qiniu:node:list',
        component: QiniuNodeList
      },
      {
        path: '/qiniu/node/group',
        name: 'menu:nodeAllocation',
        exact: true,
        key: 'qiniu:node:group',
        component: QiniuNodeGroupList
      }
    ]
  }
];
