import React, {
  createContext,
  useContext,
  useState,
  type ReactNode
} from 'react';

declare const OpencvQr: any;

// 定义 cvQr 实例的类型
interface OpencvQrContextType {
  cvQr: any | null; // OpencvQr 实例类型
  loadCvQr: () => void;
}

// 创建一个 Context，默认值为 null
const OpencvQrContext = createContext<OpencvQrContextType | undefined>(
  undefined
);

// 创建一个 Provider 组件，用于提供全局 cvQr 实例
export const OpencvQrProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [cvQr, setCvQr] = useState<any>(null);

  const loadCvQr = () => {
    if (cvQr != null) return;
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/js/opencvQr/OpencvQr.js`;
    script.async = true;

    // 等待脚本加载完成后创建 OpencvQr 实例
    script.onload = () => {
      const cvQr = new OpencvQr({
        dw: location.origin + '/js/opencvQr/detect.caffemodel',
        sw: location.origin + '/js/opencvQr/sr.caffemodel'
      });
      setCvQr(cvQr);
    };

    document.body.appendChild(script);
  };

  return (
    <OpencvQrContext.Provider value={{ cvQr, loadCvQr }}>
      {children}
    </OpencvQrContext.Provider>
  );
};

// 创建一个自定义 Hook 来方便访问 cvQr 实例
export const useOpencvQr = (): OpencvQrContextType => {
  const context = useContext(OpencvQrContext);
  if (context == null) {
    throw new Error('useOpencvQr must be used within an OpencvQrProvider');
  }
  return context;
};
