import axios, {
  type InternalAxiosRequestConfig,
  type AxiosResponse
} from 'axios';
import { store } from '@/store';
import { isEmpty } from '@/utils';
import baseConfig from '@/config';
import { Spin } from 'antd';
import ReactDOM from 'react-dom/client';

type MyAxiosRequestConfig = InternalAxiosRequestConfig<any> & {
  // 自定义请求配置属性
  isJson?: boolean;
  isCustomUrl?: boolean;
  isNoToken?: boolean;
  isAllData?: boolean;
  isLoading?: boolean;
};

const instance = axios.create({
  timeout: 300000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
});

// token失效，清除用户信息并返回登录界面
const clearAll = (): void => {
  store.dispatch({
    type: 'user/setUserInfo',
    payload: {}
  });
  store.dispatch({
    type: 'tabs/setTabs',
    payload: []
  });
  store.dispatch({
    type: 'tabs/setInitTab',
    payload: {}
  });
  store.dispatch({
    type: 'tabs/setCurrentTab',
    payload: {}
  });
};

// 添加请求拦截器
instance.interceptors.request.use(
  (config: MyAxiosRequestConfig) => {
    // 判断当前请求是否设置了不显示Loading
    if (config.isLoading !== false) {
      showLoading();
    }
    const token = store.getState().user.UserInfo.token;
    config.headers['Accept-Language'] = store.getState().user.language;
    if (!isEmpty(token)) {
      config.headers.Authorization = token;
    }
    if (config.isJson === true) {
      config.headers['Content-Type'] = 'application/json';
    }
    if (config.isCustomUrl !== true) {
      // 是否是自定义域名 否则取默认配置项
      config.url = `${baseConfig.apiPath}${config.url ?? ''}`;
    }
    if (config.isNoToken === true) {
      delete config.headers.Authorization;
    }
    config.headers['Accept-Language'] = store.getState().user.language;
    return config;
  },
  async (error) => {
    console.error(error);
    if (error.config?.isLoading !== false) {
      hideLoading();
    }
    const response = error?.response ?? error;
    const errorResponse = {
      ...(response?.data ?? response)
    };
    return await Promise.reject(errorResponse);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const result = response.data;
    const config = response.config as MyAxiosRequestConfig;
    // 判断当前请求是否设置了不显示Loading
    if (config.isLoading !== false) {
      hideLoading();
    }
    const code = result.code;
    // 返回所有的数据
    if (config.isAllData === true) {
      return response;
    }
    if (code === '1000000') {
      return result;
    } else {
      const errorResponse = { ...result };
      return Promise.reject(errorResponse);
    }
  },
  async (error) => {
    console.error(error);
    if (error.config?.isLoading !== false) {
      hideLoading();
    }
    // 未登录
    try {
      if (error.response.data.code === '1000007') {
        clearAll();
      }
    } catch (error) {}

    const response = error?.response ?? error;
    const errorResponse = {
      ...(response?.data ?? response)
    };
    return await Promise.reject(errorResponse);
  }
);

interface HttpInterface {
  post: () => Promise<AxiosResponse<any>>;
  get: () => Promise<AxiosResponse<any>>;
  put: () => Promise<AxiosResponse<any>>;
  delete: () => Promise<AxiosResponse<any>>;
  export: () => Promise<AxiosResponse<Blob>>;
  import: () => Promise<AxiosResponse<any>>;
}

const defaultConfig: CommonObjectType = {
  isLoading: false,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
};

const Http = (params: CommonObjectType = {}): HttpInterface => {
  const _ajax = async (arg: CommonObjectType): Promise<AxiosResponse<any>> => {
    return await instance(Object.assign({ ...defaultConfig }, params, arg));
  };
  return {
    // 返回一个 post请求 promise
    post: async () => await _ajax({ method: 'post' }),
    // 返回一个 get请求 promise
    get: async () => await _ajax({ method: 'get' }),
    // 返回一个 put请求 promise
    put: async () => await _ajax({ method: 'put' }),
    // 返回一个 delete请求 promise
    delete: async () => await _ajax({ method: 'delete' }),
    // 导出文件
    export: async () =>
      await _ajax({ method: 'post', responseType: 'blob', isAllData: true }),
    import: async () =>
      await _ajax({
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post'
      })
  };
};

export const $http = Http;

let requestCount = 0;
let hideLoadingTimer: ReturnType<typeof setTimeout>;

function createLoadingElement(): HTMLElement {
  const dom = document.createElement('div');
  dom.setAttribute('id', 'globalLoading');
  document.body.appendChild(dom);
  const root = ReactDOM.createRoot(dom);
  root.render(<Spin size="large" />);

  return dom;
}

export function showLoading(): void {
  // requestCount为0，才创建loading, 避免重复创建
  if (requestCount === 0) {
    clearTimeout(hideLoadingTimer);
    const loadingELement = document.getElementById('globalLoading');
    if (loadingELement == null) {
      createLoadingElement();
    }
  }
  requestCount++;
}

export function hideLoading(): void {
  // 延迟 300ms 再调用 hideLoading 方法, 合并300ms内的请求
  hideLoadingTimer = setTimeout(() => {
    requestCount--;
    if (requestCount === 0) {
      const loadingELement = document.getElementById('globalLoading');
      if (loadingELement != null) {
        document.body.removeChild(loadingELement);
        clearTimeout(hideLoadingTimer);
      }
    }
  }, 300);
}
