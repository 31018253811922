import { GlobalOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const CountryList = lazyLoad(
  async () => await import('@/pages/region/country/list')
);
const StateList = lazyLoad(
  async () => await import('@/pages/region/state/list')
);
const CityList = lazyLoad(async () => await import('@/pages/region/city/list'));

export const regionRoutes: MenuRoute[] = [
  {
    path: '/region',
    name: 'menu:regionManagement',
    key: 'region',
    identifier: '/address',
    type: 'subMenu',
    icon: <GlobalOutlined />,
    routes: [
      {
        path: '/coutry/list',
        name: 'menu:countryList',
        exact: true,
        key: 'region:coutry:list',
        component: CountryList
      },
      {
        path: '/state/list',
        name: 'menu:stateList',
        exact: true,
        key: 'region:state:list',
        component: StateList
      },
      {
        path: '/city/list',
        name: 'menu:cityList',
        exact: true,
        key: 'region:city:list',
        component: CityList
      }
    ]
  }
];
