import { UserOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const PermissionAdminList = lazyLoad(
  async () => await import('@/pages/permission/admin/list')
);
const PermissionAdminEdit = lazyLoad(
  async () => await import('@/pages/permission/admin/edit')
);
const PermissionRoleList = lazyLoad(
  async () => await import('@/pages/permission/role/list')
);
const PermissionRoleEdit = lazyLoad(
  async () => await import('@/pages/permission/role/edit')
);
const PermissionAuthList = lazyLoad(
  async () => await import('@/pages/permission/auth/list')
);
const PermissionAuthEdit = lazyLoad(
  async () => await import('@/pages/permission/auth/edit')
);
const PermissionAuthCategoryList = lazyLoad(
  async () => await import('@/pages/permission/authCategory/list')
);
const PermissionAuthCategoryEdit = lazyLoad(
  async () => await import('@/pages/permission/authCategory/edit')
);

export const permissionRoutes: MenuRoute[] = [
  {
    path: '/permission',
    name: 'menu:permissionManagement',
    key: 'permission',
    identifier: '/root/auth',
    type: 'subMenu',
    icon: <UserOutlined />,
    routes: [
      {
        path: '/permission/admin/list',
        name: 'menu:adminList',
        exact: true,
        key: 'permission:admin:list:view', // 子菜单项的 key使用了主菜单的 key 作为前缀，以确保它们与特定的主菜单项关联起来
        component: PermissionAdminList
      },
      {
        path: '/permission/admin/add',
        name: '添加管理员',
        exact: true,
        hideInMenu: true,
        key: 'permission:admin:list:add',
        selectedKey: 'permission:admin:list:view',
        component: PermissionAdminEdit
      },
      {
        path: '/permission/admin/edit',
        name: '编辑管理员',
        exact: true,
        hideInMenu: true,
        key: 'permission:admin:list:edit',
        selectedKey: 'permission:admin:list:view',
        component: PermissionAdminEdit
      },
      {
        path: '/permission/role/list',
        name: 'menu:roleList',
        exact: true,
        key: 'permission:role:list:view',
        component: PermissionRoleList
      },
      {
        path: '/permission/role/add',
        name: '添加角色',
        exact: true,
        hideInMenu: true,
        key: 'permission:role:list:add',
        selectedKey: 'permission:role:list:view',
        component: PermissionRoleEdit
      },
      {
        path: '/permission/role/edit',
        name: '编辑角色',
        exact: true,
        hideInMenu: true,
        key: 'permission:role:list:edit',
        selectedKey: 'permission:role:list:view',
        component: PermissionRoleEdit
      },
      {
        path: '/permission/auth/list',
        name: 'menu:permissionList',
        exact: true,
        key: 'permission:auth:list:view',
        component: PermissionAuthList
      },
      {
        path: '/permission/auth/add',
        name: '添加权限',
        exact: true,
        hideInMenu: true,
        key: 'permission:auth:list:add',
        selectedKey: 'permission:auth:list:view',
        component: PermissionAuthEdit
      },
      {
        path: '/permission/auth/edit',
        name: '编辑权限',
        exact: true,
        hideInMenu: true,
        key: 'permission:auth:list:edit',
        selectedKey: 'permission:auth:list:view',
        component: PermissionAuthEdit
      },
      {
        path: '/permission/auth-category/list',
        name: 'menu:permissionCategory',
        exact: true,
        key: 'permission:authcategory:list:view',
        component: PermissionAuthCategoryList
      },
      {
        path: '/permission/auth-category/add',
        name: '添加权限分类',
        exact: true,
        hideInMenu: true,
        key: 'permission:authcategory:list:add',
        selectedKey: 'permission:authcategory:list:view',
        component: PermissionAuthCategoryEdit
      },
      {
        path: '/permission/auth-category/edit',
        name: '编辑权限分类',
        exact: true,
        hideInMenu: true,
        key: 'permission:authcategory:list:edit',
        selectedKey: 'permission:authcategory:list:view',
        component: PermissionAuthCategoryEdit
      }
    ]
  }
];
