import {
  configureStore,
  type ThunkAction,
  type Action
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import promiseMiddleware from 'redux-promise';
import userSlice from '@/store/slicers/userSlice';
import appSlice from '@/store/slicers/appSlice';
import tabSlice from '@/store/slicers/tabSlice';
import rebateSlice from '@/store/slicers/rebateSlice';

const reducers = combineReducers({
  app: appSlice,
  user: userSlice,
  tab: tabSlice,
  rebate: rebateSlice
});

const persistConfig = {
  key: 'root',
  storage,
  // 以下是性能优化
  // whitelist: ['app']
  blacklist: ['rebate']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([promiseMiddleware])
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
