import { type FC, lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Loading from '@/pages/public/loading';

type Importer<T extends React.ComponentType<any>> = () => Promise<{
  default: T;
}>;

export function lazyLoad<T extends React.ComponentType<any>>(
  importer: Importer<T>
): FC<React.ComponentPropsWithoutRef<T>> {
  const Component: any = lazy(importer);

  const LazyLoadWrapper: FC<React.ComponentPropsWithoutRef<T>> = (props) => {
    return (
      <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
        <Suspense fallback={<Loading />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };

  return LazyLoadWrapper;
}
