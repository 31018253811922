import { InteractionOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const i18nProgramList = lazyLoad(
  async () => await import('@/pages/i18n/program')
);
const i18nLanguageList = lazyLoad(
  async () => await import('@/pages/i18n/language')
);
const i18nModuleList = lazyLoad(
  async () => await import('@/pages/i18n/module')
);
const i18nLocaleList = lazyLoad(
  async () => await import('@/pages/i18n/locale')
);
const i18nFieldList = lazyLoad(async () => await import('@/pages/i18n/field'));
const i18nContentList = lazyLoad(
  async () => await import('@/pages/i18n/content')
);
const i18nLanguageMangerList = lazyLoad(
  async () => await import('@/pages/i18n/language/manager')
);

export const languageRoutes: MenuRoute[] = [
  {
    path: '/i18n',
    name: 'menu:i18nManagement',
    key: 'i18n',
    identifier: '/i18n',
    type: 'subMenu',
    icon: <InteractionOutlined />,
    routes: [
      {
        path: '/i18n/language/list',
        name: 'menu:languageList',
        exact: true,
        key: 'i18n:language:list',
        component: i18nLanguageList
      },
      {
        path: '/i18n/program/list',
        name: 'menu:programList',
        exact: true,
        key: 'i18n:program:list',
        component: i18nProgramList
      },
      {
        path: '/i18n/field/list',
        name: 'menu:fieldList',
        exact: true,
        key: 'i18n:field:list',
        component: i18nFieldList
      },
      {
        path: '/i18n/locale/list',
        name: 'menu:localeList',
        exact: true,
        key: 'i18n:locale:list',
        component: i18nLocaleList
      },
      {
        path: '/i18n/module/list',
        name: 'menu:moduleList',
        exact: true,
        key: 'i18n:module:list',
        component: i18nModuleList
      },
      {
        path: '/i18n/content/list',
        name: 'menu:contentList',
        exact: true,
        key: 'i18n:content:list',
        component: i18nContentList
      },
      {
        path: '/i18n/language/manager/list',
        name: 'menu:languageManager',
        exact: true,
        key: 'i18n:language:auth:list',
        component: i18nLanguageMangerList,
        hideInMenu: true
      }
    ]
  }
];
