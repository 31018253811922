import { getCurrencyDao } from '@/service/modules/rebate';
import { handleError } from '@/service/utils';
import { message } from 'antd';
import React, { createContext, useContext, useState } from 'react';

export interface Currency {
  iso_code?: string;
  currency_type?: number;
  symbol_left?: string;
  symbol_right?: string;
  number?: number;
}

interface CurrencyContextType {
  currencies: Currency[]; // 货币单位数组
  loadCurrencies: () => Promise<void>;
}

const CurrencyContext = createContext<CurrencyContextType>({
  currencies: [],
  loadCurrencies: async () => {
    await Promise.resolve();
  }
});

export const CurrencyProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [messageApi, messageContextHolder] = message.useMessage();

  const loadCurrencies = async () => {
    if (currencies.length === 0) {
      // 如果货币单位数组为空，才去获取
      try {
        const response = await getCurrencyDao();
        setCurrencies(response.data.array ?? []);
      } catch (error) {
        setCurrencies([]);
        void messageApi.error(handleError(error));
      }
    }
  };

  return (
    <CurrencyContext.Provider value={{ currencies, loadCurrencies }}>
      {messageContextHolder}
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrencies = (): CurrencyContextType => {
  const context = useContext(CurrencyContext);
  if (context == null) {
    throw new Error('useCurrencies must be used within a CurrencyProvider');
  }
  return context;
};
