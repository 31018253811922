import { AppstoreOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const AppVersionList = lazyLoad(
  async () => await import('@/pages/appVersion/list')
);

export const appVersionRoutes: MenuRoute[] = [
  {
    path: '/app/version/list',
    name: 'menu:appVersionManagement',
    key: 'app:version',
    identifier: '/app/version',
    icon: <AppstoreOutlined />,
    component: AppVersionList
  }
];
