import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { store } from '@/store';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { COUNTRIES } from '@/constant/type/cache';
import baseConfig from '@/config';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

export const updateLanguage = () => {
  const lng = store.getState().user.language;
  const currentLng = i18n.language;
  if (lng !== currentLng) {
    // 只有语言设置变化才更新
    void i18n.changeLanguage(lng);

    if (currentLng != null) {
      // 清除缓存
      localStorage.removeItem(COUNTRIES);
      setTimeout(() => {
        location.reload();
      }, 50);
    }
  }
};

export const lng = store.getState().user.language ?? 'zh';

void i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng: 'zh',
    returnEmptyString: false,
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      caches: []
    },
    ns: ['translation', 'menu'],
    backend: {
      loadPath: `${baseConfig.i18nPath}/{{lng}}/{{ns}}.json`
    }
  })
  .then(() => {
    // Dispatch a custom event to notify the initialization is complete
    const event = new Event('i18nInitialized');
    window.dispatchEvent(event);
  });

i18n.on('languageChanged', (language) => {
  const baseTitle = i18n.t('groundPromotionSystem');
  document.title = ['test', 'local'].includes(
    process.env.REACT_APP_TYPE as string
  )
    ? `${baseTitle} (测试)`
    : baseTitle;
});

export default i18n;
