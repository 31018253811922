import { QuestionCircleOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const QuestionnaireList = lazyLoad(
  async () => await import('@/pages/questionnaire/list')
);
const EditQuestionnaire = lazyLoad(
  async () => await import('@/pages/questionnaire/edit')
);
const EditQuestion = lazyLoad(
  async () => await import('@/pages/questionnaire/edit/question/edit')
);
const AnswerList = lazyLoad(
  async () => await import('@/pages/questionnaire/answer/list')
);

export const questionnaireRoutes: MenuRoute[] = [
  {
    path: '/questionnaire/list',
    name: 'menu:questionnaireManagement',
    key: 'questionnaire',
    identifier: '/questionnaire',
    icon: <QuestionCircleOutlined />,
    component: QuestionnaireList,
    routes: [
      {
        path: '/questionnaire/add',
        name: 'menu:addQuestionnaire',
        exact: true,
        key: 'questionnaire:add',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: EditQuestionnaire
      },
      {
        path: '/questionnaire/edit',
        name: 'menu:editQuestionnaire',
        exact: true,
        key: 'questionnaire:edit',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: EditQuestionnaire
      },
      {
        path: '/questionnaire/question/add',
        name: 'menu:addQuestion',
        exact: true,
        key: 'questionnaire:question:add',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: EditQuestion
      },
      {
        path: '/questionnaire/question/edit',
        name: 'menu:editQuestion',
        exact: true,
        key: 'questionnaire:question:edit',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: EditQuestion
      },
      {
        path: '/questionnaire/sub_question/add',
        name: 'menu:addSubQuestion',
        exact: true,
        key: 'questionnaire:sub_question:add',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: EditQuestion
      },
      {
        path: '/questionnaire/sub_question/edit',
        name: 'menu:editSubQuestion',
        exact: true,
        key: 'questionnaire:sub_question:edit',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: EditQuestion
      },
      {
        path: '/questionnaire/answer/list',
        name: 'menu:viewAnswer',
        exact: true,
        key: 'questionnaire:answer:list',
        hideInMenu: true,
        selectedKey: 'questionnaire',
        component: AnswerList
      }
    ]
  }
];
