import { SolutionOutlined } from '@ant-design/icons';
import { lazyLoad } from '../util';
import { type MenuRoute } from '../types';

const SupplierList = lazyLoad(
  async () => await import('@/pages/supplier/list')
);

export const supplierRoutes: MenuRoute[] = [
  {
    path: '/supplier',
    name: 'menu:supplierManagement',
    key: 'supplier',
    identifier: '/supplier',
    type: 'subMenu',
    icon: <SolutionOutlined />,
    routes: [
      {
        path: '/supplier/list',
        name: 'menu:supplierList',
        exact: true,
        key: 'supplier:list',
        component: SupplierList
      }
    ]
  }
];
