import { EnvironmentOutlined } from '@ant-design/icons';
import { type MenuRoute } from '../types';
import { lazyLoad } from '../util';

const CheckInList = lazyLoad(async () => await import('@/pages/checkIn/list'));
const CheckInCharts = lazyLoad(
  async () => await import('@/pages/checkIn/charts')
);

export const checkInRoutes: MenuRoute[] = [
  {
    path: '/check-in',
    name: 'menu:checkInManagement',
    key: 'checkIn',
    identifier: '/clock_in',
    type: 'subMenu',
    icon: <EnvironmentOutlined />,
    routes: [
      {
        path: '/check-in/list',
        name: 'menu:checkInList',
        exact: true,
        key: 'checkIn:list',
        component: CheckInList
      },
      {
        path: '/check-in/charts',
        name: 'menu:checkInStatistics',
        exact: true,
        key: 'checkIn:charts',
        component: CheckInCharts
      }
    ]
  }
];
